import React, { useState, useEffect } from 'react';
import './CountdownTimer.css';

interface CountdownTimerProps {
  initialDays: number;
  initialHours: number;
  initialMinutes: number;
  initialSeconds: number;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ initialDays, initialHours, initialMinutes, initialSeconds }) => {
  const [days, setDays] = useState(initialDays);
  const [hours, setHours] = useState(initialHours);
  const [minutes, setMinutes] = useState(initialMinutes);
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else if (minutes > 0) {
        setMinutes(minutes - 1);
        setSeconds(59);
      } else if (hours > 0) {
        setHours(hours - 1);
        setMinutes(59);
        setSeconds(59);
      } else if (days > 0) {
        setDays(days - 1);
        setHours(23);
        setMinutes(59);
        setSeconds(59);
      } else {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [seconds, minutes, hours, days]);

  const formatTime = (time: number, label: string) => {
    const formattedTime = time < 10 ? `0${time}` : time;
    const formattedLabel = time === 1 ? label.slice(0, -1) : label;
    return `${formattedTime} ${formattedLabel}`;
  };

  return (
    <div className="countdown-timer">
      <div className="countdown-timer-item">
        {days > 0 && `${formatTime(days, 'days')}`}
      </div>
      <div className="countdown-timer-item">
      {formatTime(hours, 'hours')}
      </div>
      <div className="countdown-timer-item">
      {formatTime(minutes, 'minutes')}
      </div>
      <div className="countdown-timer-item">
      {formatTime(seconds, 'seconds')}
      </div>
    </div>
  );
};

export default CountdownTimer;