// App.tsx
import React from 'react';
import './App.css';
import StayTuned from './Components/StayTuned'; // Import the StayTuned component

function App() {
  return (
    <div className="app">
      <StayTuned launchDateTime="2025-01-01T00:00:00" />
    </div>
  );
}

export default App;